import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Reset from "../components/user/reset"
import SEO from "../components/seo"
import { isLoggedIn } from "../services/auth"
import { isBoolean } from "../utils/global"
import { REGISTRATION_TYPE_NONE } from "../utils/constants"

const ResetPage = () => {
  const isBrowser = () => typeof window !== "undefined"
  if (isBrowser() && isLoggedIn()) {
    navigate(`/dashboard`)
    return false
  }

  if (
    isBrowser() && (process.env.REGISTRATION_TYPE === REGISTRATION_TYPE_NONE ||
    isBoolean(process.env.LOGIN_WITHOUT_PASSWORD))
  ) {
    navigate(`/404`)
    return false
  }

  return (
    <React.Fragment>
      <SEO title="Reset" />
      <Reset />
    </React.Fragment>
  )
}

export default ResetPage
